import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import { PageLinks } from "./routes/routes";

// Website
import { translations as Registration } from "./components/site/Registration";
import { translations as ResetPassword } from "./components/site/ResetPassword";
import { translations as ForgotPassword } from "./components/site/ForgotPassword";
import { translations as Ö2Page } from "./components/site/sales-pages/Okuskoli2SalesPage";
import { translations as Ö1Page } from "./components/site/sales-pages/Okuskoli1SalesPage";
import { translations as HeroSection } from "./components/site/sales-pages/HeroSection";
import { translations as Footer } from "./components/site/Footer";
import { translations as FrontPage } from "./components/site/FrontPage";
import { translations as Login } from "./components/site/Login";
import { translations as NotFound } from "./components/site/NotFound";
import { translations as Navigation } from "./components/site/Navigation";
import { translations as About } from "./components/site/About";
import { translations as DrivingInstructors } from "./components/site/driving-instructor";
import { translations as Terms } from "./components/site/Terms";
import { translations as Cost } from "./components/site/blog/Cost";
import { translations as Process } from "./components/site/blog/Process";
import { translations as Unions } from "./components/site/blog/Unions";

// Inside dashboard
import { translations as Settings } from "./components/settings/index";
import { translations as TextSettings } from "./components/settings/TextSetting/index";
import { translations as DyslexiaSetting } from "./components/settings/DyslexiaSetting";
import { translations as LangToggle } from "./components/site/LangToggle";
import { translations as DashNavigation } from "./components/navigation/index";
import { translations as Feedback } from "./components/courses/feedback/index";
import { translations as Dashboard } from "./components/Dashboard";
import { translations as CourseWrapper } from "./components/CourseWrapper";
import { translations as Main } from "./components/courses/subdash/Main";
import { translations as MainText } from "./components/courses/subdash/MainText";
import { translations as DaysLeft } from "./components/courses/subdash/DaysLeft";
import { translations as Message } from "./components/courses/subdash/chatbox/Message";
import { translations as MessageForm } from "./components/courses/subdash/chatbox/MessageForm";
import { translations as NextRoundButton } from "./components/courses/subdash/NextRoundButton";
import { translations as StartAgain } from "./components/courses/subdash/StartAgain";
import { translations as Done } from "./components/courses/Done";
import { translations as MagnetForm } from "./components/courses/MagnetForm";
import { translations as DoneOkuskoliTwo } from "./components/courses/DoneOkuskoliTwo";
import { translations as Step } from "./components/courses/rounds/Step";
import { translations as PrevStepButton } from "./components/courses/rounds/PrevStepButton";
import { translations as NextStepButton } from "./components/courses/rounds/NextStepButton";
import { translations as SubmitStepButton } from "./components/courses/rounds/SubmitStepButton";
import { translations as Test } from "./components/courses/rounds/Test";
import { translations as Alert } from "./components/courses/subdash/Alert";
import { translations as CorrectAnswers } from "./components/courses/rounds/Test/TestQuestions/CorrectAnswers";
import { translations as FinalTest } from "./components/courses/FinalTest";

// Payment
import { translations as Okuskoli1Info } from "./components/payment/Okuskoli1Info";
import { translations as Okuskoli2Info } from "./components/payment/Okuskoli2Info";
import { translations as PaymentPicker } from "./components/payment/PaymentPicker";
import { translations as PaymentMethods } from "./components/payment/PaymentMethods";
import { translations as Payment } from "./components/payment/Payment";
import { translations as ThankYou } from "./components/payment/ThankYou";

// The big translation object.
// Maintained in the codebase, against all advice.
const resources: Resource = {
  is: {
    translation: {
      ...PageLinks.is,
      ...About.is,
      ...DrivingInstructors.is,
      ...Navigation.is,
      ...FrontPage.is,
      ...Footer.is,
      ...Login.is,
      ...NotFound.is,
      ...Ö1Page.is,
      ...Ö2Page.is,
      ...HeroSection.is,
      ...ForgotPassword.is,
      ...ResetPassword.is,
      ...Registration.is,
      ...Terms.is,
      ...Cost.is,
      ...Process.is,
      ...Settings.is,
      ...TextSettings.is,
      ...LangToggle.is,
      ...DashNavigation.is,
      ...Feedback.is,
      ...Dashboard.is,
      ...CourseWrapper.is,
      ...Main.is,
      ...MainText.is,
      ...DaysLeft.is,
      ...Message.is,
      ...MessageForm.is,
      ...NextRoundButton.is,
      ...StartAgain.is,
      ...Okuskoli1Info.is,
      ...Okuskoli2Info.is,
      ...PaymentPicker.is,
      ...PaymentMethods.is,
      ...Payment.is,
      ...DoneOkuskoliTwo.is,
      ...Done.is,
      ...MagnetForm.is,
      ...Step.is,
      ...PrevStepButton.is,
      ...NextStepButton.is,
      ...SubmitStepButton.is,
      ...Test.is,
      ...Alert.is,
      ...CorrectAnswers.is,
      ...FinalTest.is,
      ...ThankYou.is,
      ...DyslexiaSetting.is,
      ...Unions.is,
    },
  },
  en: {
    translation: {
      ...PageLinks.en,
      ...About.en,
      ...DrivingInstructors.en,
      ...Navigation.en,
      ...FrontPage.en,
      ...Footer.en,
      ...Login.en,
      ...NotFound.en,
      ...Ö1Page.en,
      ...Ö2Page.en,
      ...HeroSection.en,
      ...ForgotPassword.en,
      ...ResetPassword.en,
      ...Registration.en,
      ...Terms.en,
      ...Cost.en,
      ...Process.en,
      ...Settings.en,
      ...TextSettings.en,
      ...LangToggle.en,
      ...DashNavigation.en,
      ...Feedback.en,
      ...Dashboard.en,
      ...CourseWrapper.en,
      ...Main.en,
      ...MainText.en,
      ...DaysLeft.en,
      ...Message.en,
      ...MessageForm.en,
      ...NextRoundButton.en,
      ...StartAgain.en,
      ...Okuskoli1Info.en,
      ...Okuskoli2Info.en,
      ...PaymentPicker.en,
      ...PaymentMethods.en,
      ...Payment.en,
      ...DoneOkuskoliTwo.en,
      ...Done.en,
      ...MagnetForm.en,
      ...Step.en,
      ...PrevStepButton.en,
      ...NextStepButton.en,
      ...SubmitStepButton.en,
      ...Test.en,
      ...Alert.en,
      ...CorrectAnswers.en,
      ...FinalTest.en,
      ...ThankYou.en,
      ...DyslexiaSetting.en,
      ...Unions.en,
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.language || "is", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
