import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import PaymentPicker from "./PaymentPicker";
import CourseWrapper from "../CourseWrapper";
import { Link } from "react-router-dom";
import { AuthReducerTypes, IndexReducerTypes } from "../store/types";
import { RouteComponentProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    Payment: {
      Ökuskóli: "Ökuskóli",
      Greiðsla: "Greiðsla",
      "Til baka": "Til baka",
      Ö: "Ö",
    },
  },
  en: {
    Payment: {
      Ökuskóli: "Driving School",
      Greiðsla: "Payment",
      "Til baka": "Back",
      Ö: "Driving School",
    },
  },
};

type RouteParams = {
  courseId: string;
};

interface PaymentProps extends RouteComponentProps<RouteParams> {
  authReducer: AuthReducerTypes;
}

const Payment = ({ authReducer, match }: PaymentProps) => {
  const { t } = useTranslation();
  const { name, id } = authReducer;
  const { courseId } = match.params;
  const [paymentData, setPaymentData] = useState(null);
  const price = 12500;

  useEffect(() => {
    axios
      .post(`/api/payment-data`, { name, id, courseId })
      .then((res) => setPaymentData(res.data.response))
      .catch((error) => console.error(error.response));
  }, []);

  return (
    <CourseWrapper
      courseId={courseId}
      title={`${t("Payment.Ö")}${courseId} | ${t("Payment.Greiðsla")}`}
      paymentStep="step-2"
    >
      <div className="payment-page">
        <h1>{`${t("Payment.Ökuskóli")} ${courseId} — ${t(
          "Payment.Greiðsla"
        )}`}</h1>
        {paymentData !== null ? (
          <PaymentPicker
            paymentData={paymentData}
            price={price}
            courseId={courseId}
          />
        ) : null}
        <Link to={`/okuskoli${courseId}-upplysingar`} className="btn">
          <i className="fa fa-chevron-left" aria-hidden="true"></i>{" "}
          {t("Payment.Til baka")}
        </Link>
      </div>
    </CourseWrapper>
  );
};

const mapStateToProps = (state: IndexReducerTypes) => {
  const { authReducer } = state;
  return {
    authReducer,
  };
};

export default connect(mapStateToProps)(Payment);
