import React, { useState } from "react";
import PaymentMethods from "./PaymentMethods";
import PaymentOption from "./PaymentOption";
import { useTranslation } from "react-i18next";
import { PaymentData } from './PaymentData';

type PropTypes = {
  paymentData: Partial<PaymentData>;
  price: number;
  courseId: string;
};

export const translations = {
  is: {
    PaymentPicker: {
      "Veldu greiðsluleið": "Veldu greiðsluleið",
      Greiðslukort: "Greiðslukort",
      "Borgaðu með Rapyd": "Borgaðu með korti",
      Millifærsla: "Millifærsla",
      "Greiddu með millifærslu og við opnum innan við 24 tíma":
        "Greiddu með millifærslu og við opnum innan við 24 tíma",
    },
  },
  en: {
    PaymentPicker: {
      "Veldu greiðsluleið": "Choose payment type",
      Greiðslukort: "Payment card",
      "Borgaðu með Rapyd": "Pay with card",
      Millifærsla: "Bank Transfer",
      "Greiddu með millifærslu og við opnum innan við 24 tíma":
        "Pay with bank transfer and we'll open within 24 hours  ",
    },
  },
};

const PaymentPicker = ({ paymentData, price, courseId }: PropTypes) => {
  const [picked, setPicked] = useState("");
  const { t } = useTranslation();

  const handlePick = (value: string) => {
    if (value === picked) {
      setPicked("");
    } else {
      setPicked(value);
    }
  };

  const isPicked = (value: string) => {
    if (picked === value) {
      return true;
    }
    return false;
  };

  return (
    <div className="payment-area">
      <h2 className="pick-payment-type-title">
        {t("PaymentPicker.Veldu greiðsluleið")}{" "}
      </h2>
      <div className="payment-picker">
        <PaymentOption
          handlePick={handlePick}
          isPicked={isPicked}
          name="payment-card"
          prettyName={t("PaymentPicker.Greiðslukort")}
          text={t("PaymentPicker.Borgaðu með Rapyd")}
        />
        <PaymentOption
          handlePick={handlePick}
          isPicked={isPicked}
          name="transfer"
          prettyName={t("PaymentPicker.Millifærsla")}
          text={t(
            "PaymentPicker.Greiddu með millifærslu og við opnum innan við 24 tíma"
          )}
        />
      </div>
      <PaymentMethods
        picked={picked}
        paymentData={paymentData}
        price={price.toString()}
        courseId={courseId}
      />
    </div>
  );
};

export default PaymentPicker;
