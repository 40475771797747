import React, { FC } from "react";

interface TestQuestionProps {
  handlePick: (name: string) => void;
  isPicked: (name: string) => boolean;
  name: string;
  text: string;
  prettyName: string;
}

const PaymentOption: FC<TestQuestionProps> = ({
  handlePick,
  isPicked,
  name,
  text,
  prettyName,
}) => {
  const isOptionPicked = isPicked(name);

  let icon = <img className="payment-icon" src="/images/netgiro.png" />;
  if (name === "valitor") {
    icon = <i className="fa fa-credit-card fa-4x" aria-hidden="true"></i>;
  } else if (name === "transfer") {
    icon = <i className="fa fa-exchange fa-4x" aria-hidden="true"></i>;
  }

  return (
    <div
      className={`payment-choice ${isOptionPicked ? "picked" : ""}`}
      onClick={() => handlePick(name)}
    >
      <div className="checkbox">
        {isOptionPicked ? (
          <i className="fa fa-check-square-o"></i>
        ) : (
          <i className="fa fa-square-o"></i>
        )}
      </div>
      {icon}
      <h2 style={{ fontSize: "28px", marginBottom: "0", marginTop: "10px" }}>
        {prettyName}
      </h2>
      <p className="payment-option-desc">{text}</p>
    </div>
  );
};

export default PaymentOption;
