import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pages } from "../../routes/routes";

export const translations = {
  is: {
    Footer: {
      // Footer item 1
      "Hagnýtar upplýsingar": "Hagnýtar upplýsingar",
      "Allt um bílpróf": "Allt um bílpróf",
      "Hvað kostar bílpróf": "Hvað kostar bílpróf?",
      "Sparaðu með styrk frá stéttafélaginu":
        "Sparaðu með styrk frá stéttafélaginu",
      Skilmálar: "Skilmálar",
      // Footer item 2
      "Námskeið og meira": "Námskeið og meira",
      "Ökuskóli 1": "Ökuskóli 1",
      "Ökuskóli 2": "Ökuskóli 2",
      "Um okkur": "Um okkur",
      // Footer item 3
      Languages: "Tungumál og annað",
      English: "English ",
      OtherLangFrontPage: "/en",
      // Footer item 4
      Urðarbrunnur: "Urðarbrunnur",
    },
  },
  en: {
    Footer: {
      // Footer item 1
      "Hagnýtar upplýsingar": "Useful information",
      "Allt um bílpróf": "How to get a drivers license",
      "Hvað kostar bílpróf": "How much does it cost to get a drivers license?",
      "Sparaðu með styrk frá stéttafélaginu":
        "How to save money with a union grant",
      Skilmálar: "Terms and Conditions",
      // Footer item 2
      "Námskeið og meira": "Courses and more",
      "Ökuskóli 1": "Driving School 1",
      "Ökuskóli 2": "Driving School 2",
      "Um okkur": "About us",
      // Footer item 3
      Languages: "Languages and more",
      English: "Íslenska",
      OtherLangFrontPage: "/",
      // Footer item 4
      Urðarbrunnur: "Urðarbrunnur Driving School",
    },
  },
};

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-row">
        <div className="footer-item">
          <h2>{t("Footer.Hagnýtar upplýsingar")}</h2>
          <Link to={t(Pages.BlogHowToGetLicense)}>
            {t("Footer.Allt um bílpróf")}
          </Link>
          <Link to={t(Pages.BlogCostOfLicense)}>
            {t("Footer.Hvað kostar bílpróf")}
          </Link>
          <Link to={t(Pages.HowUnionsHelp)}>
            {t("Footer.Sparaðu með styrk frá stéttafélaginu")}
          </Link>
        </div>
        <div className="footer-item">
          <h2>{t("Footer.Námskeið og meira")}</h2>
          <Link to={t(Pages.DrivingSchool1)}>{t("Footer.Ökuskóli 1")}</Link>
          <Link to={t(Pages.DrivingSchool2)}>{t("Footer.Ökuskóli 2")}</Link>
          <Link to={t(Pages.AboutUs)}>{t("Footer.Um okkur")}</Link>
        </div>
        <div className="footer-item">
          <h2>{t("Footer.Languages")}</h2>
          <a href={t("Footer.OtherLangFrontPage")}>{t("Footer.English")}</a>
          <Link to={t(Pages.TermsAndConditions)}>{t("Footer.Skilmálar")}</Link>
        </div>
        <div className="footer-item">
          <h2>{t("Footer.Urðarbrunnur")}</h2>
          <p>FR/AR ehf.</p>
          <p>kt: 591214-0710</p>
          <img className="cc-logos" src="/images/visa-mastercard.gif" />
        </div>
      </div>
      <div className="bottom-footer">
        <p>
          © 2015 - {new Date().getFullYear()} - hallo@urdarbrunnur.is -
          777-9344
        </p>
      </div>
    </footer>
  );
};

export default Footer;
