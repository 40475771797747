export const pages = {
  is: {
    FrontPage: "/",
    Okuskoli1: "/okuskoli1",
    Okuskoli2: "/okuskoli2",
    Login: "/innskraning",
    Registration: "/nyskraning",
    DrivingInstructors: "/okukennarar",
    AboutUs: "/um-okkur",
    ForgotPassword: "/gleymt-lykilorð",
    ResetPassword: "/nýtt-lykilorð/:token",
    TermsAndConditions: "/skilmalar",
    BlogCostOfLicense: "/blogg/hvad-kostar-bilprof",
    BlogHowToGetLicense: "/blogg/hvernig-faer-madur-bilprof",
    Unions: "/blogg/hvernig-faer-madur-odyrt-bilprof",
    // Inside the app
    Settings: "/stillingar",
  },
  en: {
    FrontPage: "/en",
    Okuskoli1: "/driving-school-1",
    Okuskoli2: "/driving-school-2",
    Login: "/login",
    Registration: "/registration",
    DrivingInstructors: "/driving-instructor",
    AboutUs: "/about-us",
    ForgotPassword: "/forgot-password",
    ResetPassword: "/reset-password/:token",
    TermsAndConditions: "/terms-and-conditions",
    BlogCostOfLicense:
      "/blog/how-much-does-it-cost-to-get-a-drivers-license-in-Iceland",
    BlogHowToGetLicense: "/blog/how-to-get-a-drivers-license-in-Iceland",
    Unions: "/blog/how-to-get-a-cheap-drivers-license-in-Iceland",
    // Inside the app
    Settings: "/settings",
  },
};

export const getPageFromPath = ({ path, pathLang }) => {
  const targetObject = pages[pathLang];

  // Remove trailing slash if present
  let pathWithoutSlash = path;
  if (path.length > 1 && pathWithoutSlash.charAt(path.length - 1) == "/") {
    pathWithoutSlash = pathWithoutSlash.slice(0, -1);
  }

  // Find "Page" key from value.
  const page = Object.keys(targetObject).find(
    (key) => targetObject[key] === pathWithoutSlash
  );

  return page;
};

export const getPath = ({ page, lang }) => {
  return pages[lang][page];
};
