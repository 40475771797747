import React from "react";
import { useTranslation } from "react-i18next";
import CourseWrapper from "../CourseWrapper";

export const translations = {
  is: {
    ThankYou: {
      Villa: "Villa 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Eithvað hefur farið úrskeiðis okkar megin.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!",
      Villumelding: "Villumelding",
      "Greiðslu lokið": "Greiðslu lokið",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "Nú er allt klappað og klárt og þú getur hafið námskeiðið.",
      "Áfram í námskeið": "Áfram í námskeið",
    },
  },
  en: {
    ThankYou: {
      Villa: "Error 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Something has gone wrong on our end.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "If your course doesn't open please contact us at hallo@urdarbrunnur.is",
      Villumelding: "Error message",
      "Greiðslu lokið": "Payment successful",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "All is well. You can now start the course!",
      "Áfram í námskeið": "Continue to course",
    },
  },
};

const PaymentError = () => {
  const { t } = useTranslation();
  const content = (
    <div>
      <h1>{t("ThankYou.Villa")}</h1>
      <p>{t("ThankYou.Eithvað hefur farið úrskeiðis okkar megin")}</p>
      <p>
        {t(
          "ThankYou.Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!"
        )}
      </p>
      <p>
        <b>{t("ThankYou.Villumelding")}:</b> {"An unknown error occured."}
      </p>
    </div>
  );

  return (
      <CourseWrapper title={"ThankYou.Greiðslu lokið"} paymentStep="step-2">
        <div className="main-content">
            <div className="">
              {content}
            </div>
        </div>
      </CourseWrapper>
  )
};

export default PaymentError;