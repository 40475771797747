import React, { useEffect, useState } from "react";
import axios from "axios";
import CourseWrapper from "../CourseWrapper";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

export const translations = {
  is: {
    ThankYou: {
      Villa: "Villa 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Eithvað hefur farið úrskeiðis okkar megin.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!",
      Villumelding: "Villumelding",
      "Greiðslu lokið": "Greiðslu lokið",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "Nú er allt klappað og klárt og þú getur hafið námskeiðið.",
      "Áfram í námskeið": "Áfram í námskeið",
    },
  },
  en: {
    ThankYou: {
      Villa: "Error 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Something has gone wrong on our end.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "If your course doesn't open please contact us at hallo@urdarbrunnur.is",
      Villumelding: "Error message",
      "Greiðslu lokið": "Payment successful",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "All is well. You can now start the course!",
      "Áfram í námskeið": "Continue to course",
    },
  },
};

type RouteParams = {
  courseId: string;
};

const ThankYou = ({ match }: RouteComponentProps<RouteParams>) => {
  const { courseId } = match.params;
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    verifyPayment();
  }, []);

  const verifyPayment = () => {
    axios.get(`/api/course-access/${courseId}`)
      .then((res) => {
        const hasAccess = res.data.access || false;
        setError(!hasAccess); 
        setSuccess(hasAccess);
        setErrorMessage("Access to this course is not authorized.");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccess(false);
        setError(true);
        setErrorMessage("Unknown error.");
        setLoading(false);
      });
  };

  let display = null;

  if (error) {
    display = (
      <div>
        <h1>{t("ThankYou.Villa")}</h1>
        <p>{t("ThankYou.Eithvað hefur farið úrskeiðis okkar megin")}</p>
        <p>
          {t(
            "ThankYou.Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!"
          )}
        </p>
        <p>
          <b>{t("ThankYou.Villumelding")}:</b> {errorMessage}
        </p>
      </div>
    );
  } else if (success) {
    display = (
      <div>
        <h1>{t("ThankYou.Greiðslu lokið")}</h1>
        <p>
          {t(
            "ThankYou.Nú er allt klappað og klárt og þú getur hafið námskeiðið"
          )}
        </p>
      </div>
    );
  }

  return (
    <CourseWrapper title={t("ThankYou.Greiðslu lokið")} paymentStep="step-3">
      <div className="main-content">
        {loading ? (
          <div>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="">
            {display}
            <a href="/stjornbord" className="btn">
              {t("ThankYou.Áfram í námskeið")} &rarr;
            </a>
          </div>
        )}
      </div>
    </CourseWrapper>
  );
};

export default ThankYou;
