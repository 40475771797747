import React, { FC, useState } from "react";
import axios from "axios";
import { toCorrectISKFormat } from "../../utils";
import Button from "../../reusables/button";
import { useTranslation } from "react-i18next";
import { PaymentData } from "./PaymentData";

export const translations = {
  is: {
    PaymentMethods: {
      "Greiðslukort með Teya": "Greiðslukort",
      TeyaText:
        "Þú getur notað greiðslukort til að greiða fyrir námskeiðið. Hlekkurinn fyrir neðan tekur þig á örugga greiðslusíðu, síðan er þér beint aftur á okkar síðu þegar greiðslan fer í gegn.",
      "Rapyd greiðslusíðan": "Greiðslusíða",
      "Fara á greiðslusíðuna": "Fara á greiðslusíðuna",
      Upphæð: "Upphæð",
      Millifærsla: "Millifærsla",
      TransferText:
        "Ath. Eftir að þú hefur greitt með millifærslu þá geta liðið allt að 24 tímar þar til að þú færð aðgang að námskeiðinu. Ef þú getur ekki beðið og vilt byrja strax þá er betra að nota debit- eða kreditkort.",
      "Fylgdu eftirfarandi tveim skrefum til að greiða með millifærslu":
        "Fylgdu eftirfarandi tveim skrefum til að greiða með millifærslu.",
      "1. Millifæra": "1. Millifæra",
      Millifærðu: "Millifærðu",
      "inn á eftirfarandi reikning": "inn á eftirfarandi reikning",
      Kennitala: "Kennitala",
      Reikningsnúmer: "Reikningsnúmer",
      "2. Láttu okkur vita": "2. Láttu okkur vita",
      TransferText2:
        "Smelltu á takkann fyrir neðan til að senda okkur tilkynningu. Þetta sendir okkur tölvupóst svo við getum athugað hvort að greiðsla hefur borist og opnað námskeiðið fyrir þér.",
      "Senda tilkynningu": "Senda tilkynningu",
      "Tilkynning send": "Tilkynning send",
      "Í vinnslu": "Í vinnslu",
    },
  },
  en: {
    PaymentMethods: {
      "Greiðslukort með Teya": "Payment card",
      TeyaText:
        "You can use a payment card to pay for the course. The link below will take you to a secure payment page. Once you've paid you will be redirected back to this webpage.",
      "Rapyd greiðslusíðan": "Payment Page",
      "Fara á greiðslusíðuna": "Go to payment page",
      Upphæð: "Price",
      Millifærsla: "Bank Transfer",
      TransferText:
        "After you've paid with a transfer it could take up to 24 hours until you get access to the course. If you can't wait and want to begin right away then it's better to use a payment card instead.",
      "Fylgdu eftirfarandi tveim skrefum til að greiða með millifærslu":
        "Follow the following two steps to pay with a bank transfer.",
      "1. Millifæra": "1. Transfer",
      Millifærðu: "Transfer",
      "inn á eftirfarandi reikning": "into the following account",
      Kennitala: "Kennitala",
      Reikningsnúmer: "Account number (Reikningsnúmer)",
      "2. Láttu okkur vita": "2. Let us know",
      TransferText2:
        "Click on the button below to send us a notification. This sends us an email so we can check if a payment has reached us. Then we can open the course for you.",
      "Senda tilkynningu": "Send notification",
      "Tilkynning send": "Notification sent",
      "Í vinnslu": "Loading",
    },
  },
};

interface TestQuestionProps {
  paymentData: Partial<PaymentData>;
  price: string;
  picked: string;
  courseId: string;
}

const PaymentMethods: FC<TestQuestionProps> = ({
  paymentData,
  price,
  picked,
  courseId,
}) => {
  let renderedHtml = <div></div>;
  const formattedPrice = toCorrectISKFormat(Number(price));
  const [loading, setLoading] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);
  const { t } = useTranslation();

  const confirmTransfer = (courseId: string) => {
    setLoading(true);
    axios
      .post(`/api/confirm-bank-transfer`, {
        courseId: Number(courseId),
      })
      .then((res) => {
        setLoading(false);
        setNotificationSent(true);
        console.log("Done", res);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error.response);
      });
  };

  if (picked === "payment-card") {
    renderedHtml = (
      <div className="card-payment">
        <h2>{t("PaymentMethods.Greiðslukort með Teya")}</h2>
        <p>{t("PaymentMethods.TeyaText")}</p>
        <img className="valitor-sida-mynd" src="/images/valitor-sida.png"></img>
        <p className="caption">{t("PaymentMethods.Rapyd greiðslusíðan")}</p>
        <p>{`${t("PaymentMethods.Upphæð")}: ${formattedPrice}`}</p>
        <form action={paymentData.PaymentUrl} method="post">
          <input type="hidden" name="merchantid" value={paymentData.MerchantId} />
          <input type="hidden" name="paymentgatewayid" value={paymentData.PaymentGatewayId} />
          <input type="hidden" name="orderid" value={paymentData.OrderId} />
          <input type="hidden" name="reference" value={paymentData.Reference}/>
          <input type="hidden" name="checkhash" value={paymentData.checkhash} />
          <input type="hidden" name="amount" value={paymentData.Amount} />
          <input type="hidden" name="currency" value={paymentData.Currency} />
          <input type="hidden" name="language" value={paymentData.Language} />
          <input type="hidden" name="itemdescription_0" value={paymentData.ItemDescription_0} />
          <input type="hidden" name="itemcount_0" value={paymentData.ItemCount_0} />
          <input type="hidden" name="itemunitamount_0" value={paymentData.ItemUnitAmount_0} />
          <input type="hidden" name="itemamount_0" value={paymentData.ItemAmount_0} />
          <input type="hidden" name="returnurlsuccess" value={paymentData.ReturnUrlSuccess} />
          <input type="hidden" name="returnurlsuccessserver" value={paymentData.ReturnUrlSuccessServer} />
          <input type="hidden" name="returnurlcancel" value={paymentData.ReturnUrlCancel} />
          <input type="hidden" name="returnurlerror" value={paymentData.ReturnUrlError} />
          <input type="hidden" name="skipreceiptpage" value={paymentData.SkipReceiptPage} />

          <button className="btn" type="submit">
            {t("PaymentMethods.Fara á greiðslusíðuna")}{" "} <i className="fa fa-external-link" aria-hidden="true"></i>
          </button>
        </form>
      </div>
    )
  } else if (picked === "transfer") {
    renderedHtml = (
      <div className="transfer-payment">
        <h2>{t("PaymentMethods.Millifærsla")}</h2>
        <p>{t("PaymentMethods.TransferText")}</p>
        <p>
          {t(
            "PaymentMethods.Fylgdu eftirfarandi tveim skrefum til að greiða með millifærslu"
          )}
        </p>
        <h3>{t("PaymentMethods.1. Millifæra")}</h3>
        <p>
          {t("PaymentMethods.Millifærðu")} {formattedPrice}{" "}
          {t("PaymentMethods.inn á eftirfarandi reikning")}:
        </p>
        <div
          style={{
            padding: "10px 16px",
            backgroundColor: "#fff4d6",
            display: "block",
            fontFamily: "monospace",
          }}
        >
          <p style={{ fontFamily: "monospace" }}>
            <b>{t("PaymentMethods.Kennitala")}</b>
            <br /> 591214-0710
            <br />
            <b style={{ marginTop: "12px", display: "inline-block" }}>
              {t("PaymentMethods.Reikningsnúmer")}
            </b>
            <br /> 0133-26-005912
            <br />
            <b style={{ marginTop: "12px", display: "inline-block" }}>
              {t("PaymentMethods.Upphæð")}
            </b>
            <br /> {formattedPrice}
          </p>
        </div>
        <h3>{t("PaymentMethods.2. Láttu okkur vita")}</h3>
        <p>{t("PaymentMethods.TransferText2")}</p>
        {notificationSent ? (
          <p>
            ✔️ <em>{t("PaymentMethods.Tilkynning send")}</em>
          </p>
        ) : (
          <Button type="onClick" onClick={() => confirmTransfer(courseId)}>
            <i className="fa fa-envelope-o" aria-hidden="true"></i>{" "}
            {loading
              ? t("PaymentMethods.Í vinnslu")
              : t("PaymentMethods.Senda tilkynningu")}
          </Button>
        )}
      </div>
    );
  }

  return <div>{renderedHtml}</div>;
};

export default PaymentMethods;
