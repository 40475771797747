import React, { useState } from "react";
import FormButton from "../../../../reusables/formButton";
import { useApi } from "../../../../hooks";
import { openCourse } from "../../../../api/courseApi";
import { useParams } from "react-router-dom";

const OpenForm = () => {
	const { userId } = useParams<{ userId: string }>();
	const [form, setForm] = useState({
		course: "1",
		paymentType: "transfer",
		amount: 12500,
	});

	const { status, message, exec: initOpenCourse } = useApi(openCourse);

	const submitForm = async (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
		event.preventDefault();
		initOpenCourse(form.course, form.paymentType, form.amount, parseInt(userId));
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { value, name } = e.target;
		setForm({ ...form, [name]: value });
	};

	const handleTyping = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		setForm({ ...form, [name]: value });
	};

	const { course, paymentType, amount } = form;
	return (
		<div>
			<h2>Open</h2>
			<div>
				<label>Course</label>
				<select name="course" onChange={handleSelectChange} value={course}>
					<option value="1">Ökuskóli 1</option>
					<option value="2">Ökuskóli 2</option>
				</select>
			</div>
			<div>
				<label>Payment type</label>
				<select name="paymentType" onChange={handleSelectChange} value={paymentType}>
					<option value="transfer">Millifærsla</option>
					<option value="teya">Teya</option>
				</select>
			</div>

			<div>
				<label>Upphæð</label>
				<input name="amount" onChange={handleTyping} type="text" value={amount}></input>
			</div>

			<FormButton size="tiny" text="Open course" onClick={submitForm} />
			{status === "SUCCESS" && "Course opened"}
			{status === "PENDING" && "Loading"}
			{status === "ERROR" && message}
		</div>
	);
};

export default OpenForm;
