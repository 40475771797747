import React, { useEffect, useState } from "react";
import axios from "axios";
import queryString from "query-string";
import CourseWrapper from "../CourseWrapper";
import { History, Location } from "history";
import { useTranslation } from "react-i18next";

export const translations = {
  is: {
    ThankYou: {
      Villa: "Villa 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Eithvað hefur farið úrskeiðis okkar megin.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!",
      Villumelding: "Villumelding",
      "Greiðslu lokið": "Greiðslu lokið",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "Nú er allt klappað og klárt og þú getur hafið námskeiðið.",
      "Áfram í námskeið": "Áfram í námskeið",
    },
  },
  en: {
    ThankYou: {
      Villa: "Error 😨",
      "Eithvað hefur farið úrskeiðis okkar megin":
        "Something has gone wrong on our end.",
      "Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!":
        "If your course doesn't open please contact us at hallo@urdarbrunnur.is",
      Villumelding: "Error message",
      "Greiðslu lokið": "Payment successful",
      "Nú er allt klappað og klárt og þú getur hafið námskeiðið":
        "All is well. You can now start the course!",
      "Áfram í námskeið": "Continue to course",
    },
  },
};

type PropTypes = {
  location: Location;
  history: History;
};

const ThankYou = ({ location, history }: PropTypes) => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    notifyBackendOfPayment();
  }, []);

  const notifyBackendOfPayment = () => {
    const values = queryString.parse(location.search);
    if (!values.DigitalSignatureResponse) history.push("/stjornbord");
    const { DigitalSignatureResponse, ReferenceNumber } = values;
    const body = { DigitalSignatureResponse, ReferenceNumber };

    if (
      DigitalSignatureResponse !== undefined ||
      ReferenceNumber !== undefined
    ) {
      axios
        .post(`/api/payment-successful`, body)
        .then(() => {
          setLoading(false);
          setSuccess(true);
          setError(false);
        })
        .catch((error) => {
          console.error(error.response);
          setLoading(false);
          setError(true);
          setErrorMessage(error.response.data.error);
        });
    } else {
      setLoading(false);
      setError(true);
      setErrorMessage("Query not defined.");
    }
  };

  let display = null;

  if (error) {
    display = (
      <div>
        <h1>{t("ThankYou.Villa")}</h1>
        <p>{t("ThankYou.Eithvað hefur farið úrskeiðis okkar megin")}</p>
        <p>
          {t(
            "ThankYou.Ef þú kemst ekki inn á námskeiðið skaltu hafa samband við hallo@urdarbrunnur.is. Við biðjumst velvirðingar á þessu!"
          )}
        </p>
        <p>
          <b>{t("ThankYou.Villumelding")}:</b> {errorMessage}
        </p>
      </div>
    );
  } else if (success) {
    display = (
      <div>
        <h1>{t("ThankYou.Greiðslu lokið")}</h1>
        <p>
          {t(
            "ThankYou.Nú er allt klappað og klárt og þú getur hafið námskeiðið"
          )}
        </p>
      </div>
    );
  }

  return (
    <CourseWrapper title={t("ThankYou.Greiðslu lokið")} paymentStep="step-3">
      <div className="main-content">
        {loading ? (
          <div>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="">
            {display}
            <a href="/stjornbord" className="btn">
              {t("ThankYou.Áfram í námskeið")} &rarr;
            </a>
          </div>
        )}
      </div>
    </CourseWrapper>
  );
};

export default ThankYou;
